<template>
  <div class="modal-backdrop fade show" bis_skin_checked="1"></div>
  <div
    class="modal fade show"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    bis_skin_checked="1"
    style="display: block; padding-left: 0px"
    aria-modal="true"
    role="dialog"
  >
    <div class="modal-dialog modal-content">
      <div class="modal-header" bis_skin_checked="1">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="closePopup"
        />
      </div>
      <buyer-form
        class="modal-body"
        v-if="buyer.id"
        :buyer="buyer"
        :submitFunction="editBuyer"
      />
    </div>
  </div>
</template>

<script>
import BuyerForm from "@/components/buyer/BuyerForm";
export default {
  name: "FixBuyerModal",
  components: { BuyerForm },
  data() {
    return {
      customer: {},
    };
  },
  props: {
    invoiceSummary: {
      type: Object,
      required: true,
    },
    closePopup: {
      type: Function,
      required: true,
    },
  },
  computed: {
    buyer() {
      return this.$store.getters.buyer;
    },
  },
  methods: {
    async editBuyer(buyer) {
      await this.$store.dispatch("editBuyer", buyer);
      await this.$store.dispatch("getInvoice", this.invoiceSummary.id);
    },
  },
  async created() {
    await this.$store.dispatch("getBuyer", this.invoiceSummary.customerID);
  },
};
</script>

<style scoped></style>
