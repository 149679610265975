import * as XLSX from "xlsx";

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

const exportToExcel = (data, sheetname, filename, dataHeaders) => {
  const keys = Object.keys(dataHeaders);
  const headers = Object.values(dataHeaders);

  const filteredData = data.map((d) =>
    keys.reduce((acc, key) => {
      // eslint-disable-next-line no-prototype-builtins
      if (d.hasOwnProperty(key)) {
        acc[key] = d[key];
      }
      return acc;
    }, {})
  );

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(filteredData, {
    header: keys,
  });
  XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetname);
  const binaryString = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "binary",
  });
  const blob = new Blob([s2ab(binaryString)], {
    type: "application/octet-stream",
  });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
};

export { s2ab, exportToExcel };
